<template>
  <v-dialog :value="dialog && !neverShow && (isIos() || isMac())" max-width="500">
    <v-card>
      <v-card-title>
        Trouble enabling location?
        <v-spacer></v-spacer>
        <v-icon icon @click="dialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <div>
          <h2 class="primary--text">Location services</h2>
          <div v-if="isIos()" class="ml-2">
            You can turn Location Services on or off at
            <b>Settings > Privacy > Location Services</b>.
          </div>
          <div v-if="isMac()" class="ml-3">
            1. On your Mac, choose Apple menu > System Preferences, click Security & Privacy , then
            click Privacy.
            <br />
            If the lock at the bottom left is locked , click it to unlock the preference pane.
            <br />
            2. Click Location Services on the left.
            <br />
            3. Select Enable Location Services
          </div>
          <v-btn
            color="primary"
            text
            class="v-btn--active mt-2 text-capitalize"
            v-if="isIos()"
            large
            to="/features/land?focus=Video-Loom-PhoneLocation&highlight=Video-Loom-PhoneLocation"
            >Watch Video <v-icon>mdi-play</v-icon></v-btn
          >
        </div>
        <div class="mt-2" v-if="isIos() && browser()">
          <h2 class="primary--text">Allow {{ browser() }} to access location</h2>
          <div class="ml-3">
            You can enable location acces for {{ browser() }} at
            <b>Settings > Privacy > Location Services > {{ browser() }}</b>
            <br />
            Change <b>Allow Location Access</b> to
            <br />
            <b>Ask Next Time or When I Share</b>
            <br />
            OR <br />
            <b>While Using the App</b>
          </div>
          <v-btn
            v-if="isIos()"
            color="primary"
            text
            class="v-btn--active mt-2 text-capitalize"
            large
            to="/features/land?focus=Video-Loom-iPhonePrivacy&highlight=Video-Loom-iPhonePrivacy"
            >Watch Video <v-icon>mdi-play</v-icon></v-btn
          >
        </div>
        <div class="text-right mt-3">
          <v-btn small text color="info" class="text-capitalize" @click="neverShow = true"
            >Never show this
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  computed: {
    dialog: {
      get() {
        return this.$store.state.dialogs.locationPermissionError;
      },
      set(value) {
        this.$store.commit('setDialog', { key: 'locationPermissionError', value });
      }
    },
    neverShow: {
      get() {
        return this.$store.state.preference.neverShowLocationPermissionError;
      },
      set(value) {
        this.$store.commit('setPreference', { key: 'neverShowLocationPermissionError', value });
      }
    }
  },
  methods: {
    browser() {
      let userAgent = navigator.userAgent;
      let browserName;
      if (userAgent.match(/chrome|chromium|crios/i)) {
        browserName = 'chrome';
      } else if (userAgent.match(/firefox|fxios/i)) {
        browserName = 'firefox';
      } else if (userAgent.match(/safari/i)) {
        browserName = 'safari';
      } else if (userAgent.match(/opr\//i)) {
        browserName = 'opera';
      } else if (userAgent.match(/edg/i)) {
        browserName = 'edge';
      } else {
        browserName = '';
      }
      return browserName;
    },
    isMac() {
      return navigator.userAgent.toLowerCase().includes('os x');
    },
    isIos() {
      return /(iPhone|iPod|iPad)/i.test(navigator.userAgent);
    }
  }
};
</script>

<style scoped lang="scss">
h2 {
  font-size: 1.1rem !important;
}
</style>
